import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useRouter } from "next/router";
import { useCallback } from "react";

import { pagesPath } from "src/utils/$path";

export type LoginFlow = {
  currentStep: LoginStep | null;
};

export type LoginStepPhoneNumber = {
  id: "phoneNumber";
  shopCardId: string;
};

export type LoginStepVerificationCode = {
  id: "verificationCode";
  shopCardId: string;
  verificationId: string;
  internationalNumber: string;
};

export type LoginStepProfile = {
  id: "profile";
  shopCardId: string;
  verificationId: string;
  internationalNumber: string;
};

export type LoginStep =
  | LoginStepPhoneNumber
  | LoginStepVerificationCode
  | LoginStepProfile;

const loginFlowAtom = atomWithStorage<LoginFlow>(
  "login-flow",
  {
    currentStep: null,
  },
  undefined,
  {
    getOnInit: true,
  },
);

export const useLoginFlow = () => {
  const router = useRouter();
  const [loginFlow, setLoginFlow] = useAtom(loginFlowAtom);
  const { currentStep } = loginFlow;

  const reset = () => {
    setLoginFlow({
      currentStep: null,
    });
  };

  const goToPhoneNumberStep = async ({
    shopCardId,
  }: {
    shopCardId: string;
  }) => {
    if (currentStep != null) return;

    setLoginFlow({
      currentStep: {
        id: "phoneNumber",
        shopCardId,
      },
    });
    await router.push(pagesPath.login.phoneNumber.$url());
  };

  const goToVerificationCodeStep = async ({
    verificationId,
    internationalNumber,
  }: {
    verificationId: string;
    internationalNumber: string;
  }) => {
    if (currentStep?.id != "phoneNumber") return;

    setLoginFlow({
      currentStep: {
        id: "verificationCode",
        shopCardId: currentStep.shopCardId,
        verificationId,
        internationalNumber,
      },
    });
    await router.push(pagesPath.login.verificationCode.$url());
  };

  // 再送
  const changeVerificationId = async ({
    verificationId,
  }: {
    verificationId: string;
  }) => {
    if (currentStep?.id != "verificationCode") return;

    setLoginFlow({
      currentStep: {
        id: "verificationCode",
        shopCardId: currentStep.shopCardId,
        internationalNumber: currentStep.internationalNumber,
        verificationId,
      },
    });
  };

  const goToProfileStep = async () => {
    if (currentStep?.id != "verificationCode") return;

    setLoginFlow({
      currentStep: {
        id: "profile",
        shopCardId: currentStep.shopCardId,
        verificationId: currentStep.verificationId,
        internationalNumber: currentStep.internationalNumber,
      },
    });
    await router.push(pagesPath.login.profile.$url());
  };

  const complete = async () => {
    const shopCardId = currentStep?.shopCardId;
    if (!shopCardId) return;

    setLoginFlow({
      currentStep: null,
    });
    await router.push(pagesPath._shopCardId(shopCardId).checkin.$url());
  };

  const handlePopState = useCallback(() => {
    if (!currentStep) return;

    if (currentStep.id === "phoneNumber") {
      setLoginFlow({
        currentStep: null,
      });
    }
    if (currentStep.id === "verificationCode") {
      setLoginFlow({
        currentStep: {
          id: "phoneNumber",
          shopCardId: currentStep.shopCardId,
        },
      });
    }
    if (currentStep.id === "profile") {
      setLoginFlow({
        currentStep: {
          id: "verificationCode",
          shopCardId: currentStep.shopCardId,
          verificationId: currentStep.verificationId,
          internationalNumber: currentStep.internationalNumber,
        },
      });
    }
  }, [currentStep, setLoginFlow]);

  return {
    currentStep: loginFlow.currentStep,
    reset,
    goToPhoneNumberStep,
    goToVerificationCodeStep,
    goToProfileStep,
    complete,
    changeVerificationId,
    handlePopState,
  };
};
