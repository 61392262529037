import {
  Box,
  Button,
  CloseButton,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowRightLeft, LogOut, Menu } from "lucide-react";
import { FC } from "react";

import { Logo } from "../Logo";

import { FetchViewerQuery } from "src/gql/__generated__/graphql";

type Viewer = FetchViewerQuery["viewer"];

type Props = {
  viewer: Viewer;
  onSwitchLanguage: () => void;
  onLogout: () => void;
};

export const AuthenticatedHeader: FC<Props> = ({
  viewer,
  onLogout,
  onSwitchLanguage,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box borderBottomWidth="1px" borderBottomColor="gray.200" px={4} h="72px">
      <HStack w="full" justifyContent="space-between" h="100%">
        <Logo />

        <Button
          as={IconButton}
          icon={<Menu />}
          variant="ghost"
          color="black"
          colorScheme="white"
          _active={{ bg: "none" }}
          onClick={onOpen}
        />
      </HStack>

      <HamburgerMenu
        viewer={viewer}
        isOpen={isOpen}
        onCloseDrawer={onClose}
        onLogout={onLogout}
        onSwitchLanguage={onSwitchLanguage}
      />
    </Box>
  );
};

type HamburgerMenuProps = {
  viewer: Viewer;
  isOpen: boolean;
  onCloseDrawer: () => void;
  onSwitchLanguage: () => void;
  onLogout: () => void;
};

const HamburgerMenu: FC<HamburgerMenuProps> = ({
  viewer,
  isOpen,
  onCloseDrawer,
  onLogout,
  onSwitchLanguage,
}) => {
  return (
    <Box>
      <Drawer isOpen={isOpen} onClose={onCloseDrawer}>
        <DrawerOverlay />
        <DrawerContent borderLeftRadius="20px">
          <DrawerHeader m={2}>
            <HStack justifyContent="space-between">
              <VStack alignItems="start" spacing={0}>
                <Text fontWeight={800} fontSize="md">
                  {`${viewer.profile?.lastNameKana} ${viewer.profile?.firstNameKana}`}
                </Text>
                <Text fontSize="xs" color="gray.300">
                  {viewer.profile?.phone}
                </Text>
              </VStack>
              <CloseButton onClick={onCloseDrawer} />
            </HStack>
          </DrawerHeader>

          <Divider />

          <DrawerBody m={2}>
            <VStack spacing={2} alignItems="start">
              <Button
                p={0}
                leftIcon={<ArrowRightLeft />}
                variant="ghost"
                color="black"
                onClick={onSwitchLanguage}
              >
                EN/JP
              </Button>

              <Button
                p={0}
                leftIcon={<LogOut />}
                variant="ghost"
                color="black"
                onClick={onLogout}
              >
                ログアウト
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
