import { useQuery } from "@apollo/client";

import {
  FetchViewerDocument,
  FetchViewerQuery,
} from "src/gql/__generated__/graphql";

export const useFetchViewer = () => {
  const { data, refetch, loading, error } = useQuery<FetchViewerQuery>(
    FetchViewerDocument,
    {
      variables: {},
      ssr: false,
    },
  );

  return {
    viewer: data?.viewer,
    loading,
    error,
    refetch,
  };
};
