import {
  Box,
  Button,
  Center,
  Divider,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { memo } from "react";
import { useAsyncFn } from "react-use";

import { FragmentType, getFragmentData } from "src/gql/__generated__";
import {
  FetchViewerQuery,
  ShopCardUnitFragmentDoc,
} from "src/gql/__generated__/graphql";

type Props = {
  viewer: FetchViewerQuery["viewer"] | null;
  shopCardFragment: FragmentType<typeof ShopCardUnitFragmentDoc>;
  onCheckin: () => Promise<void>;
  onLogoutAndCheckin: () => Promise<void>;
};

export const CheckinScreen = memo<Props>(
  ({ viewer, shopCardFragment, onCheckin, onLogoutAndCheckin }) => {
    const shopCard = getFragmentData(ShopCardUnitFragmentDoc, shopCardFragment);
    const shop = shopCard.shop;
    const [checkinState, doCheckin] = useAsyncFn(onCheckin, [onCheckin]);

    return (
      <Stack p={4} spacing={8}>
        <Center py={6}>
          <Text textStyle="Paragraph/Large">ご来店ありがとうございます。</Text>
        </Center>
        <Stack spacing={8}>
          <Center>
            <Box
              position="relative"
              bgImage="url('/shopcard.png')" // TODO: 画像のパスを指定
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="cover"
              w="300px"
              h="180px"
              p={5}
              borderRadius="md"
            >
              <Text
                position="absolute"
                textStyle="Label/Small"
                color="#B29973"
                bottom="8px"
                right="8px"
              >
                S-{shopCard.code}
              </Text>
            </Box>
          </Center>
          <Stack spacing={2}>
            <Text textStyle="Label/Large">{shop.name}</Text>
            <Text textStyle="Paragraph/Small">{`${shop.prefecture}${shop.city}${shop.address}`}</Text>
          </Stack>

          <Divider color="semantic.border.opaque" />

          <Stack spacing={4}>
            <Text textStyle="Paragraph/Medium">
              チェックインをしていただくと、特別な会員機能をご利用いただくことができます。
            </Text>
            <UnorderedList textStyle="Paragraph/Medium">
              <ListItem>次回来店時にご利用いただけるお得なクーポン</ListItem>
              <ListItem>ご来店時に召し上がったお料理やお飲み物の記録</ListItem>
            </UnorderedList>
          </Stack>
          <Box>
            <Button
              w="full"
              size="lg"
              isLoading={checkinState.loading}
              onClick={() => doCheckin()}
            >
              チェックイン
            </Button>
          </Box>

          {!!viewer && !!viewer.profile && (
            <VStack mt={4} spacing={1}>
              <Text textStyle="Paragraph/Medium">
                <Text as="span" fontWeight={600} mr={1}>
                  {`${viewer.profile.lastNameKana} ${viewer.profile.firstNameKana}`}
                </Text>
                様としてログイン中です。
              </Text>
              <Text textStyle="Paragraph/XSmall">
                別のアカウントとしてログインする場合は
                <Text
                  as="a"
                  textDecoration="underline"
                  onClick={() => {
                    onLogoutAndCheckin();
                  }}
                >
                  こちら
                </Text>
              </Text>
            </VStack>
          )}
        </Stack>
      </Stack>
    );
  },
);
