import type { Query as Query_fqac4i } from '../pages/index.page';

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  _shopCardId: (shopCardId: string | number) => ({
    "checkin": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/[shopCardId]/checkin' as const, query: { shopCardId }, hash: url?.hash })
    }
  }),
  "home": {
    "memberships": {
      _membershipId: (membershipId: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/home/memberships/[membershipId]' as const, query: { membershipId }, hash: url?.hash })
      })
    },
    "visits": {
      _visitId: (visitId: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/home/visits/[visitId]' as const, query: { visitId }, hash: url?.hash })
      })
    }
  },
  "login": {
    "phoneNumber": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/login/phoneNumber' as const, hash: url?.hash })
    },
    "profile": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/login/profile' as const, hash: url?.hash })
    },
    "verificationCode": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/login/verificationCode' as const, hash: url?.hash })
    }
  },
  $url: (url: { query: Query_fqac4i, hash?: string | undefined }) => ({ pathname: '/' as const, query: url.query, hash: url.hash })
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  circles_svg: '/circles.svg',
  next_svg: '/next.svg',
  shopcard_png: '/shopcard.png',
  speria_logo_png: '/speria_logo.png',
  speria_logo_2x_png: '/speria_logo_2x.png',
  speria_logo_3x_png: '/speria_logo_3x.png',
  turborepo_svg: '/turborepo.svg',
  vercel_svg: '/vercel.svg'
} as const;

export type StaticPath = typeof staticPath;
