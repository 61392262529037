import { Box, Flex, Text } from "@chakra-ui/react";
import Head from "next/head";
import { FC, ReactNode, useCallback } from "react";

import { AuthenticatedHeader } from "../AuthenticatedHeader/AuthenticatedHeader";
import { Header } from "../Header/Header";

import { useFetchViewer } from "src/hooks/useFetchViewer";

type Props = {
  pageTitle?: string;
  children: ReactNode;
};

export const MainLayout: FC<Props> = ({ pageTitle, children }) => {
  const { viewer } = useFetchViewer();

  const handleLogout = useCallback(() => {}, []);

  const handleSwitchLanguage = useCallback(() => {}, []);

  return (
    <>
      <Head>
        {pageTitle ? (
          <title>Speria | {pageTitle}</title>
        ) : (
          <title>Speria</title>
        )}
      </Head>

      <Flex direction="column" minHeight="100vh">
        {viewer ? (
          <AuthenticatedHeader
            viewer={viewer}
            onLogout={handleLogout}
            onSwitchLanguage={handleSwitchLanguage}
          />
        ) : (
          <Header />
        )}

        <Box flex="1" p={4}>
          {children}
        </Box>

        <Box as="footer" p={4} textAlign="center">
          <Text fontSize="sm">Speria, Inc.</Text>
        </Box>
      </Flex>
    </>
  );
};
